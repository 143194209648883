.icon__scholarship__container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:16px 55px 16px 0px;
    border: none;
}
.scholarship__update{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 1px;
    padding-right: 1px;
    border-bottom: none;
}
.scholarship__delete{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 1px;
    padding-right: 1px;
    border-bottom: none;
}
.children__hoancanh{
    width: 220px;
    min-width: 160px;
}
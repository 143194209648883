.title__hompage__one {
    color: rgb(32, 101, 209);
    margin-top: 80px;
    padding-left: 80px;
}

.title__hompage__two {
    color: rgb(32, 101, 209);
    margin-top: 40px;
    padding-left: 80px;
}

.divider__hompage {
    height: 1.5px;
    background-color: black;
    margin: 0px 80px;
}

.card {
    display: flex;
    justify-content: center;
    padding: 30px 120px;
}

.card__container {
    margin: 40px 15px 25px 15px;
    max-width: 350px;
}

.card__content {
    min-height: 200px;
}

.card__container img {
    width: 100%;
    object-fit: cover;
    height: 140px;
}

.card__content h4 {
    color: rgb(32, 101, 209);
    height: 50px;
    margin: 5px;
}

.card__content h4:hover {
    color: rgb(16, 57, 150);
}

.card__content p {
    height: 90px;
}

.card__content .card__p {
    margin-top: 20px;
    height: 45px;
}

.card__action .card__button {
    background-color: rgb(32, 101, 209);
    color: #fff;
}

.card__action .card__button:hover {
    background-color: rgb(16, 57, 150);
}

.dialog-container {
    overflow-y: auto;
    height: 100%;
}



.dialog-container::-webkit-scrollbar {
    width: 6px;
}

.dialog-container::-webkit-scrollbar-track {
    background-color: transparent;
}

.dialog-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
}

.dialog-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.dialog-container::-webkit-scrollbar-thumb:active {
    background-color: #333;
}

.img-slider-treem {
    margin: 15px;
    border-radius: 15px;
    height: 270px;
}

.img-slider-treem img {
    border-radius: 15px;
    height: 300px;
    max-width: 100%,

}
.buttonthemtreem{
    border-radius: 0px;
    border: 2px solid rgb(28, 28, 165);
    background-color:rgb(28, 28, 165) ;
    padding: 4px 12px;
    
    height: 40px;
    width: 150px;
}
.icon__children__container{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding:16px 40px 16px 10px; */
    border: none;
}
.children__update{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 1px;
    padding-right: 1px;
    border-bottom: none;
}
.children__delete{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 1px;
    padding-right: 1px;
    border-bottom: none;
}
.dialogCreateUser > div > div {
  border-radius: 0px;
  max-width: 800px;
  min-width: 600px;
}
.titlecreateuser {
  display: flex;
  margin: 1rem;
  font-weight: 700;
  padding-top: 10px;
  padding-left: 20px;
  font-size: 20px;
}
.titlecreateuser > button {
  margin-left: 20rem;
}
.titlecreateuser > button:hover {
  margin-left: 20rem;
  color: black;
  background-color: rgb(241, 84, 84);
}

.divider {
  height: 3px;
  background: linear-gradient(blue, 20%, pink);
}
.form__info{
  padding: 20px 15px;
}
.form__info__container{
  display: flex;
  justify-content: center;
}
.form__info__container>.formcontrolcreateuser {  
  min-width: 240px;
  margin:15px 15px;
}


.formcontrolcreateuser > div {
  border-radius: 0px;
}

.formcontrolcreateuser > div:hover {
  border: none;
}
.formcontrolcreateuser > div > div {
  border-radius: 0px;
}
.formcontrol__changerole{
  width: 550px;
  min-width: 320px;
  margin:15px 15px;
}
.formcontrol__changerole > div {
  border-radius: 0px;
}

.formcontrol__changerole > div:hover {
  border: none;
}
.formcontrol__changerole > div > div {
  border-radius: 0px;
}
.form__address{
  display: flex;
  justify-content: center;
}
.form__address__info{
  
}
.form__address__info .form__address__quan{

}
.form__address__info .form__address__quan >.formcontrolcreateuser{
  width: 260px;
  min-width: 240px;
  margin:15px 15px;
  
}
.form__address__info .form__address__phuong{
  
}
.form__address__info .form__address__phuong >.formcontrolcreateuser{
  width: 260px;
  min-width: 240px;
  margin:15px 15px;
}
.themtaikhoan {
  margin-bottom: 13px;
  margin-right: 30px;
  border-radius: 0px;
  border: 2px solid rgb(28, 28, 165);
  background-color: rgb(28, 28, 165);
  padding: 4px 12px;
  color: #ffff;
  height: 40px;
  width: 150px;
}
.themtaikhoan:hover {
  border-radius: 0px;
  border: 2px solid rgb(34, 34, 139);
  background-color: rgb(34, 34, 139);
  padding: 4px 12px;
  color: #ffff;
  height: 40px;
  width: 150px;
}


.container{
    position: relative;
}
.heading {
    margin-top: 45px;
    font-size: 40px;
    font-weight: 600;
    color: #2c444e;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heading::after {
    content: "";
    width: 550px;
    height: 4px;
    border-radius: 1px;
    bottom: -20px;
    background-color: #2c444e;
    position: absolute;
}

.form_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    width: 550px;
    height: 250px;
    background-color: rgb(242, 242, 242);
    box-shadow: 3px 4px 36px -6px rgba(0,0,0,0.4);
    border-radius: 2%;
    /* position: relative; */
    position: absolute;
    top: -30%;
    left: 50%;
    transform: translate(-50%, 30%); 

    
    
}


.img {
    width: 160%;
    position: absolute;
    left: -150px;
    top: -50px;
}

.center {
    flex: 2;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.from_heading {
    font-size: 15px;
    font-weight: 400;
    color: #6393a6;
    justify-content: center;
    margin-top: 5px;
}

.input {
    width: 350px;
    height: 40px;
    padding: 5px;
    margin: 5px 0;
    outline: none;
    border: 1px solid #2c444e;
    border-radius: 2px;
    font-size: 13px;
}

.text {
    font-size: 14px;
    color: #3b5763;
    margin: 0px 0 15px 0;
    padding: 15px;
}
.text > a{
    font-size: 13px;
    font-weight: 500;
    color: rgb(26, 139, 253);
    text-decoration: none;
    
}

.google_btn {
    width: 350px;
    height: 45px;
    border-radius: 2%;
    border: none;
    outline: none;
    background-color: rgb(218, 15, 15);
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    font-size: 16px;
    font-weight: 500;
    margin: 0px 0 5px 0;
    color: #2c444e;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.google_btn > img{
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.google_btn > span{
    margin-left: 35px;
    color: white;
}
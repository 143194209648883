.dialogcreatescholarship > div > div {
  border-radius: 0px;
  max-width: 800px;
  min-width: 600px;
  }
  .titlecreatesholarship {
    display: flex;
    margin: 1rem;
    font-weight: 700;
    padding-top: 10px;
    padding-left: 20px;
    font-size: 20px;
  }
  .titlecreatesholarship > button {
    margin-left: 26rem;
  }
  .titlecreatesholarship > button:hover {
    margin-left: 26rem;
    color: black;
    background-color: rgb(241, 84, 84);
  }
  
  .divider {
    height: 3px;
    background: linear-gradient(blue, 20%, pink);
  }
  .form__info__createscholarship{
    padding: 20px 15px;
  }
  .form__info__createscholarship__container{
    display: flex;
    justify-content: center;
  }

  .form__info__createscholarship__container> .formcontrolcreatesholarship {
    width:285px;
    min-width: 240px;
    margin:15px 15px;
  }

  
  .formcontrolcreatesholarship > div {
    border-radius: 0px;
    margin: 0;
  }
  
  .formcontrolcreatesholarship > div > div {
    border-radius: 0px;
  }
  .formcontrolcreatesholarship__name{
    width: 600px;
    min-width: 320px;
    margin:15px 15px;
  }
  .formcontrolcreatesholarship__name > div{
    border-radius: 0px;
  }
  .formcontrolcreatesholarship__name > div:hover{
    border: none;
  }
  .formcontrolcreatesholarship__name > div> div{
    border-radius: 0px;
  }
  .form__info__moneyscholarship__container {
    display: flex;
    justify-content: center;
  }
  .form__info__moneyscholarship__container > .formcontrolcreatesholarship{
    width:180px;
    min-width: 180px;
    margin:15px 15px;
  }
  .formcontrolcreatesholarship__note{
    width: 600px;
    min-width: 320px;
    margin:15px 15px;
  }
  .formcontrolcreatesholarship__note > div{
    border-radius: 0px;
  margin: 0;
  }
  .formcontrolcreatesholarship__note > div:hover{
    border: none;
  }
  .formcontrolcreatesholarship__note >div > div{
    border-radius: 0px;
  }
  .huythemhocbong {
    border-radius: 0px;
    margin-right: 15px;
    margin-bottom: 13px;
    border: 2px solid rgb(230, 41, 41);
    padding: 4px 12px;
    color: #ffff;
    height: 40px;
    width: 80px;
  }
  .huythemhocbong:hover {
    border-radius: 0px;
    margin-right: 15px;
    margin-bottom: 13px;
    border: 2px solid rgb(230, 41, 41);
    background-color: rgb(230, 41, 41);
    padding: 4px 12px;
    color: #ffff;
    height: 40px;
    width: 80px;
  }
  .themhocbong {
    border-radius: 0px;
    margin-right: 20px;
    margin-bottom: 13px;
    border: 2px solid rgb(28, 28, 165);
    background-color: rgb(28, 28, 165);
    padding: 4px 12px;
    color: #ffff;
    height: 40px;
    width: 160px;
  }
  .themhocbong:hover {
    border-radius: 0px;
    border: 2px solid rgb(34, 34, 139);
    background-color: rgb(34, 34, 139);
    padding: 4px 12px;
    color: #ffff;
    height: 40px;
    width: 160px;
  }
.maincontainer>h4 {
    font-weight: bold;
}

.typography {
    margin-top: -10px;
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.5rem;
    font-family: Public Sans, sans-serif;


}

.container__ten {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container__diachi {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container__hoctap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container__donvi {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container__hoancanh {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formcontrol__inform {
    width: 350px;
}

.formcontrol__inform>div {
    padding: 10px 15px;

}

.formcontrol__inform>div>label {
    padding: 10px 15px;
}

.formcontrol__hoancanh {
    width: 665px;
}

.formcontrol__hoancanh>textarea {
    padding: 10px 15px;
    height: 92px;
    line-height: 1.4375em;
    font-size: 1rem;
    font-family: Public Sans, sans-serif;
    font-weight: 400;
    margin-top: 18px;
    margin-bottom: 4px;
    width: 100%;
    color: #637381;
    box-sizing: border-box;
    cursor: text;
    border-color: rgba(145, 158, 171, 0.32);
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;

}

.formcontrol__hoancanh>textarea:hover {
    border-color: black;
}

.formcontrol__hoancanh>textarea:focus-visible {
    outline: "2px solid transparent"
}

.Card {
    position: relative;
}

/* .block__content {
    position: absolute;
    top: 5px;
    right: 5px;
  } */

.button_show_more {
    background-color: whitesmoke;
    color: black;
    border-radius: 20px;
    width: 200px;
    border: 2px solid rgba(180, 180, 180, 0.5);
}

.button_show_more:hover {
    background-color: lightgray;
}

.button_remove_hoctap {
    margin-left: auto;
    margin-right: 0.5rem;
    width: 40px;
    height: 40px;
    margin-top: 13px;
}

.button_remove_hoctap:hover {
    color: black;
    background-color: rgb(241, 84, 84);
}

.button_remove_doituong {
    margin-top: -2px;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    color: rgb(222, 158, 158);
}

.button_remove_doituong:hover {
    color: black;
    background-color: rgb(241, 84, 84);
}
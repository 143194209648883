.buttondanhsach{
    border-radius: 0px;
    border: 2px solid #1F7244;
    background-color:#1F7244 ;
    padding: 4px 12px;
    margin-right: 16px; 
    height: 40px;
    width: 150px;
}
.buttondanhsach:hover{
    background-color:#1F7244 ;
}
.buttonxuatexcel{
    border-radius: 0px;
    border: 2px solid #1F7244;
    background-color:white ;
    color: #1F7244;
    padding: 4px 12px;
    margin-right: 16px; 
    height: 40px;
    width: 150px;
}
.buttonThemMoi{
    border-radius: 0px;
    border: 2px solid rgb(28, 28, 165);
    background-color:rgb(28, 28, 165) ;
    padding: 4px 12px;
    
    height: 40px;
    width: 150px;
}
.buttonThemMoi{
   
}
.block__container{
    padding:16px 25px;
    align-items: center;
}
.block__content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 5px;
    padding-right: 5px;
    
    
}
.block__content:hover{
    position: relative;
    background-color: rgba(145, 158, 171, 0.08);
    width: 40px;
}
.icon__container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:16px 20px 16px 20px;
    border: none;
}
.updateuser{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 1px;
    padding-right: 1px;
    border-bottom: none;
    
}
.deleteuser{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 1px;
    padding-right: 1px;
    border-bottom: none;
    
}
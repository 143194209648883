.previewBlog{
    text-align: justify;
    overflow-y: auto;
    padding: 50px;
}

.image-container img{
    display: block;
    margin: auto;
}


.dialogInsertUser {
  margin-top: 80px;
}
.dialogInsertUser > div > div {
  border-radius: 0px;
  max-width: 800px;
  min-width: 600px;
}
.titleupdateuser {
  display: flex;
  margin: 1rem 0rem 1rem 2.5rem;
  font-weight: 700;
  padding-top: 10px;
  font-size: 20px;
}
.titleupdateuser > button {
  margin-left: 21rem;
}
.titleupdateuser > button:hover {
  margin-left: 21rem;
  color: black;
  background-color: rgb(241, 84, 84);
}
.form__info{
  padding: 20px 15px;
}
.form__info__container{
  display: flex;
  justify-content: center;
}
.form__info__container>.formcontrolupdateuser {
  margin: 1rem 0 0rem 0rem;
  min-width: 240px;
  margin:15px 15px;
}
.formcontrolupdateuser > div > div {
  border-radius: 0px;
  
}
.formcontrolupdateuser > div {
  border-radius: 0px;
}
.formcontrolupdate__changerole{
  width: 550px;
  min-width: 320px;
  margin:15px 15px;
}
.formcontrolupdate__changerole > div {
  border-radius: 0px;
}
.formcontrolupdate__changerole > div:hover {
  border: none;
}
.formcontrolupdate__changerole > div > div {
  border-radius: 0px;
}
.form__update__address{
  display: flex;
  justify-content: center;
}
.form__update__address__info .form__update__address__quan{

  margin:15px 15px;
}
.form__update__address__info .form__update__address__quan >.formcontrolupdateuser{
  width: 260px;
  min-width: 240px; 
}
.form__update__address__info .form__update__address__phuong{
  margin:15px 15px;
}
.form__update__address__info .form__update__address__phuong >.formcontrolupdateuser{
  width: 260px;
  min-width: 240px;
}

.divider {
  height: 3px;
  background: linear-gradient(blue, 20%, pink);
}
.capnhat {
  border-radius: 0px;
  margin-right: 30px;
  margin-bottom: 13px;
  border: 2px solid rgb(28, 28, 165);
  background-color: rgb(28, 28, 165);
  padding: 4px 12px;
  color: #ffff;
  height: 40px;
  width: 150px;
}
.capnhat:hover {
  border-radius: 0px;
  border: 2px solid rgb(34, 34, 139);
  background-color: rgb(34, 34, 139);
  padding: 4px 12px;
  color: #ffff;
  height: 40px;
  width: 150px;
}

.createExcelModal > div > div {
  border-radius: 0px;
  max-width: 800px;
  min-width: 600px;
}
.titlecreateuserexcel {
  display: flex;
  margin: 1rem;
  font-weight: 700;
  padding-top: 10px;
  padding-left: 20px;
  font-size: 20px;
}
.titlecreateuserexcel > button {
  margin-left: 25rem;
}
.titlecreateuserexcel > button:hover {
  margin-left: 25rem;
  color: black;
  background-color: rgb(241, 84, 84);
}

.divider {
  height: 3px;
  background: linear-gradient(blue, 20%, pink);
}
.namecontent {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: rgb(37, 37, 36);
  opacity: 85%;
}
.groupbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
}
.downloadbutton {
  width: 170px;
  height: 40px;
  margin-right: 8px;
  border-radius: 0px;
  color: white;
  position: relative;
  background-color: #1f7244;
}
.downloadbutton:hover {
  width: 170px;
  height: 40px;
  margin-right: 8px;
  border-radius: 0px;
  position: relative;
  background-color: #1f7244;
}
.downloadbutton a {
  text-decoration: none;
  display: block;
  color: white;
  position: absolute;
  border-radius: 0px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  line-height: 40px;
}
.uploadbutton {
  width: 170px;
  height: 40px;
  margin-right: 8px;
  border-radius: 0px;
  position: relative;
  color: white;
  background-color: rgb(28, 28, 165);
}
.uploadbutton:hover {
  width: 170px;
  height: 40px;
  margin-right: 8px;
  border-radius: 0px;
  position: relative;
  color: white;
  background-color: rgb(28, 28, 165);
}

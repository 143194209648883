.icon__doituong__container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:16px 55px 16px 35px;
    border: none;
}
.doituong__update{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 1px;
    padding-right: 1px;
    border-bottom: none;
}
.doituong__delete{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 1px;
    padding-right: 1px;
    border-bottom: none;
}

.menu_item{
opacity: 1;
transform: none;
min-width: 180px;
transition: opacity 385ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 256ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
top: 80px;

transform-origin: 107px 521.7px;
}
.child_menu_item{
    top:80px;
}
.menu_item_phuong{
    top: 80px;
}
.child_menu_item_phuong{
    top: 80px;
}
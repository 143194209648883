.dialogupdatescholarship > div > div {
  border-radius: 0px;
  max-width: 800px;
  min-width: 600px;
}
.titleupdatescholarship {
  display: flex;
  margin: 1rem;
  font-weight: 700;
  padding-top: 10px;
  padding-left: 20px;
  font-size: 20px;
}
.titleupdatescholarship > button {
  margin-left: 26rem;
}
.titleupdatescholarship > button:hover {
  margin-left: 26rem;
  color: black;
  background-color: rgb(241, 84, 84);
}

.divider {
  height: 3px;
  background: linear-gradient(blue, 20%, pink);
}
.form__info__updatescholarship {
  padding: 20px 15px;
}
.form__info__updatescholarship__container {
  display: flex;
  justify-content: center;
}

.form__info__updatescholarship__container >.formcontrolupdatesholarship {
  width: 300px;
  min-width: 240px;
  margin: 15px 15px;
}

.formcontrolupdatesholarship > div {
  border-radius: 0px;
  margin: 0;
}

.formcontrolupdatesholarship > div > div {
  border-radius: 0px;
}
.formcontrolupdatesholarship__name {
  width: 630px;
  min-width: 320px;
  margin: 15px 15px;
}
.formcontrolupdatesholarship__name > div {
  border-radius: 0px;
  
}
.formcontrolupdatesholarship__name > div:hover {
  border: none;
}
.formcontrolupdatesholarship__name > div > div {
  border-radius: 0px;
}
.form__info__moneyupdatescholarship__container{
    display: flex;
    justify-content: center;
}
.form__info__moneyupdatescholarship__container > .formcontrolupdatesholarship{
    width:190px;
    min-width: 190px;
    margin:15px 15px;
}
.formcontrolupdatesholarship__note {
    width: 630px;
    min-width: 320px;
    margin:15px 15px;
}
.formcontrolupdatesholarship__note > div{
    border-radius: 0px;
  margin: 0;
  }
  .formcontrolupdatesholarship__note > div:hover{
    border: none;
  }
  .formcontrolupdatesholarship__note >div > div{
    border-radius: 0px;
  }
  .huycapnhathocbong {
    border-radius: 0px;
    margin-right: 15px;
    margin-bottom: 13px;
    border: 2px solid rgb(28, 28, 165);
    background-color: rgb(28, 28, 165);
    padding: 4px 12px;
    color: #ffff;
    height: 40px;
    width: 80px;
  }
  .huycapnhathocbong:hover {
    border-radius: 0px;
    margin-right: 15px;
    margin-bottom: 13px;
    border: 2px solid rgb(230, 41, 41);
    background-color: rgb(230, 41, 41);
    padding: 4px 12px;
    color: #ffff;
    height: 40px;
    width: 80px;
  }
  .capnhathocbong {
    border-radius: 0px;
    margin-right: 20px;
    margin-bottom: 13px;
    border: 2px solid rgb(28, 28, 165);
    background-color: rgb(28, 28, 165);
    padding: 4px 12px;
    color: #ffff;
    height: 40px;
    width: 160px;
  }
  .capnhathocbong:hover {
    border-radius: 0px;
    border: 2px solid rgb(34, 34, 139);
    background-color: rgb(34, 34, 139);
    padding: 4px 12px;
    color: #ffff;
    height: 40px;
    width: 160px;
  }

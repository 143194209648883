.error {
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  
}

.iconerror {
  fill: red;
  width: 60px;
  height: 60px;
  opacity: 1;
  z-index: 1;
}
.error h3 {
  color: #1b4d9c;
  opacity: 1;
  margin-bottom: 0px;
}
.error p{
    margin-bottom: 30px; 
}

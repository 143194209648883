.slider_one{
    background-image: url(../../../assets/images/home/banner.png);

    width: 100%;
    height: calc(100vw / 3);
    background-size: cover;
    background-repeat: no-repeat;
}
.slider_two{
    background-image: url(../../../assets/images/home/banner.png);
    width: 100%;
    height: calc(100vw / 3);
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
}
.slider_three{
    background-image: url(../../../assets/images/home/banner.png);
    width: 100%;
    height: calc(100vw / 3);
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
}

/* .slick-dots li{
    height: 30px;
    width: 30px;
}
.slick-dots li button::before{
    height: 50px;
    width: 50px;
} */
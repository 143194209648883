.sponsor {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  overflow: auto;
}
.marquee{
  background-color: rgb(192,215,240);
}
.image_wrapper {
  margin: 10px 30px;
}

.image_wrapper img {
  width: 80%;
  height: 60px;
}
.image__NhaTaiTro{
  width: 100%;
  padding: 30px 80px;
  overflow: auto;
  display: 'flex';
  flex-direction: 'column-reverse';
}
.image__NhaTaiTro img{
  width: 100%;
  height: 150px;
  padding: 10px 15px;

}
.title__form__informchildrens{
  width: 100%;
  text-align: center;
  margin-top: 80px;
}
.title__form__informchildrens h2{
  font-size: 28px;
}
.form__informchildren__container{
display: flex;

}
.form__informchildren{
  width: 50%;
  margin-top: 80px;
  margin-bottom: 50px;
  text-align: center;
  
  
}
.form__informchildren fieldset{
  border: none;
}
.form__informchildren h2{
  color: rgb(32,101,217);
}
.divider__communitypage {
  height: 1.5px;
  background-color: #000000;
  width: 450px;
  margin: 0px 130px;
}
/* .form__informchildren__control{
  text-align: center;

} */
.title__form__informchildren{
  text-align: left;
  margin-left: 130px;
}
.form__content{
  width: 450px;
  border-radius: 5px;
  height: 50px;
  line-height: 2px;
  border:1px solid rgb(164, 164, 164);
  margin: 8px 0px;
  background-color: rgb(226, 225, 225) ;
}
.form__content:focus-visible{
  outline-color: #1b3c9e;
  border: 0.5px;
  background-color:rgb(240,248,255);
}
.form__hoancanh{
  width: 450px;
  border-radius: 5px;
 font-family:Arial, Helvetica, sans-serif;
  border:1px solid rgb(164, 164, 164);
  margin: 8px 0px;
  background-color: rgb(226, 225, 225) ;
}
.form__hoancanh:focus-visible{
  outline-color: #1b3c9e;
  border: 0.5px;
  background-color:rgb(240,248,255);
}
.form__informchildren button{
  width: 90px;
  height: 40px;
  background-color: #3366FF;
  color: #fff;
  border-radius: 5px;
  border:none;
  margin-top: 15px;
  margin-left: 355px;
}
.footer {
  align-items: center;
  /* text-align: center; */
  background-color: rgb(22, 66, 90);
}
.footer__container {
  /* display: flex;
    justify-content: center;
    text-align: center; */
  text-align: center;
  align-items: center;
  color: #fff;
  padding: 15px;
}
